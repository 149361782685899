import React, { useState } from 'react'
import { Button } from '../component/common/button'
import { pricingData } from '../config/const'
import { useTranslation } from 'react-i18next'
import '../styles/custom/pricing.css'
import CountryInfo from '../component/common/country'
import DownloadModal2 from '../component/page/access/DownloadModal2'

const Pricing = () => {
    const { t, i18n } = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <section id='features' className='md:px-20 px-4 mt-[100px] flex flex-col md:gap-[104px] gap-6 items-center'>
                <div className='flex flex-col gap-6 text-center'>
                    <h2>{t("price-title")}</h2>
                    <h6>{t("price-description")}</h6>
                </div>
                <div className='grid xl:grid-cols-3 grid-cols-1 gap-4'>
                    {pricingData.map((data, index) =>
                        <div className='card-container' key={`pricing-${index}`}>
                            <div className={`price-card md:w-[416px] md:h-[438px] w-[344px] h-[411px] relative md:p-8 p-6 ${data.active && 'border-[2px] border-[#FF5B0433]'}`}>
                                <div className='flex flex-col md:gap-8 gap-6'>
                                    <div className='flex flex-col md:gap-6 gap-4'>
                                        <h3>{t(data.label)}</h3>
                                        <label>{t(data.price)}</label>
                                    </div>
                                    <p>{t(data.description)}</p>
                                    <ul className='flex flex-col gap-2'>
                                        {data.items.map((item, index) =>
                                            <li className='flex flex-row gap-2 items-center' key={`price-data-${index}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
                                                    <path d="M0.8284 1.17163L3.65683 4.00006L0.8284 6.82849" stroke="#FF5B04" />
                                                </svg>
                                                <div className='opacity-60'>{t(item)}</div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className='absolute bottom-8'>
                                    <Button className={`button-${data.button.type}`} onClick={() => setOpenModal(true)}>
                                        {t(data.button.label)}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div>
                    <CountryInfo />
                </div>
            </section>
            {openModal && <DownloadModal2 handleClose={() => setOpenModal(false)} />}
        </>

    )
}

export default Pricing
