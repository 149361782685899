import React, { useState, useEffect, useCallback } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import '../../styles/custom/country.css';
import Papa from 'papaparse';

const CountryInfo = () => {
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [countryInfo, setCountryInfo] = useState(null);
  const [countryData, setCountryData] = useState([]);

  const loadCSVData = useCallback(() => {
    fetch('/country-info-list.csv')
      .then(response => response.text())
      .then(csvText => {
        Papa.parse(csvText, {
          header: true, // Use the first row as headers
          skipEmptyLines: true,
          dynamicTyping: false, // Leave values as strings
          complete: (result) => {
            if (result.errors.length > 0) {
              console.error("CSV Parsing Errors:", result.errors);
              setCountryData([]); // Set to empty array on error
              return;
            }
            setCountryData(result.data);
          },
          error: (error) => {
            console.error("CSV Parsing Error:", error);
            setCountryData([]);
          }
        });
      })
      .catch(error => {
        console.error("Error loading CSV file:", error);
        setCountryData([]);
      });
  }, []);

  useEffect(() => {
    loadCSVData();
  }, [loadCSVData]);

  useEffect(() => {
    if (selectedCountryCode) {
        const foundCountry = countryData.find(
        (country) => country["COUNTRY"] === selectedCountryCode
      );
      setCountryInfo(foundCountry);
    } else {
      setCountryInfo(null);
    }
  }, [selectedCountryCode, countryData]);

  const isRestricted = (value) => value === "RESTRICTED";

  const handleCountryChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  return (
    <div className="country-info-container">
      <div className="country-dropdown-container">
        <label htmlFor="countrySelect">Can you get a Solar Card in your country?</label>
        <select
            id="countrySelect"
            value={selectedCountryCode}
            onChange={handleCountryChange}
            className="country-select"
        >
            <option value="">Select a country</option>
            {countryData.map((country) => (
                <option key={country["COUNTRY"]} value={country["COUNTRY"]}>
                    {country["COUNTRY"]}
                </option>
            ))}
        </select>
      </div>

      {countryInfo && (
        <div className="p-[16px] border-[2px] border-[#FF5B0433] rounded-[16px]">
          <h2 className='mb-[24px]'>{countryInfo["COUNTRY"]}</h2>
          <p><span className='text-[#ffffff88]'>Restricted:</span> {isRestricted(countryInfo["COMPLIANCE RESTRICTED"]) ? 'Yes' : 'No'}</p>
          <p><span className='text-[#ffffff88]'>Global Card Program (Plastic):</span> {countryInfo.PLASTIC}</p>
          <p><span className='text-[#ffffff88]'>Global Card Program (Virtual):</span> {countryInfo.VIRTUAL}</p>
          <p><span className='text-[#ffffff88]'>Plastic Card Delivery Cost:</span> {countryInfo["PLASTIC DELIVERY COST"]}</p>
          <p><span className='text-[#ffffff88]'>IBAN Available:</span> {countryInfo.IBANS}</p>
        </div>
      )}

      {!countryInfo && selectedCountryCode && (
        <div className="no-info">
          <p>No information available for the selected country.</p>
        </div>
      )}
    </div>
  );
};

export default CountryInfo;
