import React, { useState } from 'react'
import logo from '../../../assets/image/logo.png'
import { useTranslation } from 'react-i18next'
import Input from '../../common/input'
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import CheckBox from '../../common/checkbox';
import { Button } from '../../common/button';
import DownloadModal from './DownloadModal';
import axiosInstance from '../../../api/axiosInstance';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const AccessModal = ({ handleClose }) => {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [checked, setChecked] = useState(false)
    const methods = useForm();
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState('success');
    const [message, setMessage] = useState('');

    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const onSubmit = async (data) => {
        // window.grecaptcha.ready(() => {
        //     window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(async (token) => {
        if (checked) {
            window.open("https://dashboard.solarcard.app/sign-up", '_blank', 'noopener,noreferrer');
            setMessage("Success!");
            setStatus('success');
            setOpen(true);
            // data.recaptcha_token = token;
            // data.site_key = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
            // try {
            //     const result = await axiosInstance.post('/solarcard-signup', data);
            //     handleModalOpen();
            //     setMessage("Success!");
            //     setStatus('success');
            //     setOpen(true);
            // } catch (error) {
            //     setStatus('error');
            //     if (error.response.data.message)
            //         setMessage(error.response.data.message);
            //     else
            //         setMessage("Something wrong with server!");
            //     setOpen(true);
            // }
        }
        //     })
        // })
    }

    const handleModalOpen = () => {
        setOpenModal(true);
        document.body.style.overflow = 'hidden';
    }

    const handleModalClose = () => {
        setOpenModal(false);
        handleClose();
        document.body.style.overflow = 'unset';
    }

    const handleBackgroundClick = (e) => {
        if (e.target.classList.contains('modal-container')) {
            handleClose();
        }
    };

    return (
        <div className='fixed modal-container w-full h-full' onClick={handleBackgroundClick}>
            {!openModal && <div className='relative md:left-1/2 top-1/2 md:-translate-x-1/2 -translate-y-1/2 modal-background md:w-[568px] md:h-auto w-auto h-auto mx-4 flex flex-col gap-12 pt-8 pb-10 md:px-12 px-6 items-center'>
                <div className='absolute right-9 top-6 cursor-pointer' onClick={handleClose}>
                    <span className='text-white opacity-40 hover:opacity-100 text-[25px]'>&times;</span>
                </div>
                <img src={logo} alt='logo' />
                <div className='flex flex-col gap-4 text-center'>
                    <h3>{t("access-modal-header")}</h3>
                    <p className='opacity-60 md:w-auto w-[280px]'>{t("access-modal-description")}</p>
                </div>
                <FormProvider {...methods}>
                    <form className='flex flex-col gap-10 items-center' onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className='flex flex-col gap-6'>
                            <div className='flex flex-col gap-4'>
                                <Input
                                    type='text'
                                    name='name'
                                    label='Name'
                                    placeholder={t("access-modal-name")}
                                />
                                <Input
                                    type='email'
                                    name='email'
                                    label='Email'
                                    placeholder={t("access-modal-email")}
                                />

                            </div>
                            <CheckBox
                                checked={checked}
                                setChecked={setChecked}
                            />
                        </div>
                        <button type={checked ? 'submit' : 'button'}>
                            <Button className={`${checked ? 'button-primary' : 'button-primary-disabled'}`}>
                                {t("header-button")}
                            </Button>
                        </button>
                    </form>
                </FormProvider>
            </div>}
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} autoHideDuration={3000} onClose={handleToastClose}>
                <Alert
                    onClose={handleToastClose}
                    severity={status}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
            {openModal && <DownloadModal handleClose={handleModalClose} />}
        </div>
    )
}

export default AccessModal
